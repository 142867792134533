/* eslint-disable indent */
import { t, h, Component } from '@app/utils'
import { Form, Button, MessagePopup } from '@app/elements'
import './index.scss'
import { Operations } from '@app/api'
import { getTrustIPsArray } from './utils'

import operations from '@app/modules/Operations'


const restrictToRegex = (str, regex) => {
    if (str.match(regex) !== null) {
        return true
    }
    return false
}
const applicationName =(localStorage.getItem('overrideapi') ?? '').split('-')[0]

const state = {
    datas: {
        doctors: {
            caregiverTeamSupervisorId: '',
            listCaregiverId: '',
            operation: '', // add | remove
        },
        initialTrustIPs: [],
        trustIPs: '',
        interopSources:[],
        interopSourceInputValue: '',
    },
    errors: {
        form: {
            caregiverTeamSupervisorId: [],
            listCaregiverId: [],
        },
    },
    pageError: {
        displayed: false,
        kind: 'success',
        message: '',
    },
    formIsSubmitting: false,
}

const getTrustIPs = (state, actions) => {
    Operations.getTrustIP().then((res) => {
        actions.setState({
            key: 'datas',
            value: {
                ...state.datas,
                initialTrustIPs: res.data,
            },
        })
    })
}

const getInteropSources = (state, actions) => {
    Operations.getInteropSources().then((res) => {
        actions.setState({
            key: 'datas',
            value: {
                ...state.datas,
                interopSources: res.data,
            },
        })
    })
}
const getInitialStates =  async(state,actions)=>{
    const initialTrustIPs=  await Operations.getTrustIP()
    const initialInteropSources=  await Operations.getInteropSources()

    actions.setState({
        key: 'datas',
        value: {
            ...state.datas,
            interopSources: initialInteropSources.data,
            initialTrustIPs: initialTrustIPs.data
        },
    })

}

const isValidInteropSourceId= (id)=>{
    const regex = new RegExp(/^[a-z0-9-]+$/)
    return !!id.match(regex)
}

const isNotEmptySourceCustomersList= (state)=>{
    let isNotEmpty = false
    state.datas.interopSources.entry.map((en)=> {

        if(en.label===state.datas.interopSourceInputValue) {
          if(en.customers.length !==0){
              isNotEmpty= true
          }
        }
    })
    return isNotEmpty
}

const actions = {
    onComponentCreate: (props) => async (state, actions) => {
        getInitialStates(state, actions)
    },
    resetErrors:
        (targetField = null) =>
        (state, actions) => {
            let errors = Object.keys(state.errors.form).reduce((acc, curr) => {
                acc[curr] =
                    !targetField || targetField === curr
                        ? []
                        : state.errors.form[curr]
                return acc
            }, {})
            //
            actions.setState({
                key: 'errors',
                value: { ...state.errors, form: errors },
            })

            return errors
        },
    isValidDatas: () => (state, actions) => {
        let errors = actions.resetErrors()
        //
        let valid = true
        if (state.datas.doctors.caregiverTeamSupervisorId === '') {
            errors.caregiverTeamSupervisorId = t(
                'Veuillez saisir l\'id du soignant cible'
            )
            valid = false
        }
        if (state.datas.doctors.listCaregiverId === '') {
            errors.listCaregiverId = t(
                'Veuillez saisir un ou plusieurs id(s) de soignant(s) à ajouter ou supprimer'
            )
            valid = false
        }
        actions.setState({
            key: 'errors',
            value: { ...state.errors, form: errors },
        })
        return valid
    },
    handleAPIErrors: (err) => (state, actions) => {
        if (err.length > 0) {
            let errorsMessages = {
                listCaregiverId: err
                    .filter(
                        (er) => er.propertyPath.indexOf('listCaregiver') > -1
                    )
                    .map((er) => er.message),
                caregiverTeamSupervisorId: err
                    .filter(
                        (er) =>
                            er.propertyPath.indexOf('caregiverTeamSupervisor') >
                            -1
                    )
                    .map((er) => er.message),
            }
            actions.setState({
                key: 'errors',
                value: { ...state.errors, form: errorsMessages },
            })
        }
    },
    updateDoctorsTeam: (operation) => (state, actions) => {
        if (!actions.isValidDatas()) {
            return false
        }
        actions.setState({ key: 'formIsSubmitting', value: true })

        const { caregiverTeamSupervisorId, listCaregiverId } =
            state.datas.doctors
        Operations.updateDoctorNursesTeam({
            caregiverTeamSupervisorId: caregiverTeamSupervisorId,
            listCaregiverId: listCaregiverId.split(','),
            operation: operation,
        })
            .then((res) => {
                actions.setState({ key: 'formIsSubmitting', value: false })
                if (res.data.extras && res.data.extras.errors) {
                    actions.handleAPIErrors(res.data.extras.errors)
                } else {
                    actions.setState({
                        key: 'pageError',
                        value: {
                            displayed: true,
                            kind: 'success',
                            message: 'L’opération a été réalisée avec succès',
                        },
                    })
                }
            })
            .catch(() => {
                //
            })
    },
    modifyTrustIP: (operation) => (state, actions) => {
        actions.setState({ key: 'formIsSubmitting', value: true })

        const trustIPsArray = getTrustIPsArray(state.datas.trustIPs)

        const apiCall =
            operation === 'add'
                ? Operations.addTrustIP
                : Operations.removeTrustIP

        let messageIP = 'L’opération a été réalisée avec succès'
        let kindMessageIP = 'success'

        apiCall(trustIPsArray)
            .then((res) => {
                actions.setState({ key: 'formIsSubmitting', value: false })

                if (res.data.extras && res.data.extras.errors) {
                    actions.handleAPIErrors(res.data.extras.errors)
                } else {
                    if (res.data.already_exists?.length > 0) {
                        messageIP = `${JSON.stringify(
                            res.data.already_exists
                        )} already exists`
                        kindMessageIP = 'error'
                    } else if (res.data.invalid?.length > 0) {
                        messageIP = `${JSON.stringify(
                            res.data.invalid
                        )} is invalid`
                        kindMessageIP = 'error'
                    } else if (res.data.not_existing?.length > 0) {
                        messageIP = `${JSON.stringify(
                            res.data.not_existing
                        )} does not exist`
                        kindMessageIP = 'error'
                    }
                    getTrustIPs(state, actions)
                    actions.setState({
                        key: 'pageError',
                        value: {
                            displayed: true,
                            kind: kindMessageIP,
                            message: messageIP,
                        },
                    })
                }
            })
            .catch(() => {})
    },
    modifyInteropSources: (operation) =>(state, actions) => {
        actions.setState({ key: 'formIsSubmitting', value: true })
      
        let messageInterop = 'L’opération a été réalisée avec succès'
        let kindMessageInterop = 'success'

        const interopSourceId = state.datas.interopSourceInputValue

        if (isNotEmptySourceCustomersList(state)){
            messageInterop="Des établissements sont liés à cette source. Retirer le lien depuis la page établissement avant de procéder"
            kindMessageInterop = 'error'
            actions.setState({
                key: 'pageError',
                value: {
                    displayed: true,
                    kind: kindMessageInterop,
                    message: messageInterop,
                },
            })
            return
        }
        if(!isValidInteropSourceId(interopSourceId)){
            messageInterop = 'Nom de source non valide. Uniquement des lettres et des chiffres autorisés, sans espace'
            kindMessageInterop = 'error'
            actions.setState({
                key: 'pageError',
                value: {
                    displayed: true,
                    kind: kindMessageInterop,
                    message: messageInterop,
                },
            })
            return
        }
        const apiCall =
            operation === 'add'
                ? Operations.addInteropSource
                : Operations.removeInteropSource

        apiCall(interopSourceId)
            .then((res) => {
                actions.setState({ key: 'formIsSubmitting', value: false })
                        getInteropSources(state, actions)
                        actions.setState({
                            key: 'pageError',
                            value: {
                                displayed: true,
                                kind: kindMessageInterop,
                                message: messageInterop,
                            },
                        })

            })
            .catch(() => {})
    },
    setState:
        ({ key, value }) =>
        (state) => ({
            [key]: value,
        }),
}


const view =

    (state, actions) => ({ match }, children) =>
        (
            <div className={'btzAdminForm'}>
                {state.pageError.displayed && (
                    <MessagePopup
                        kind={state.pageError.kind}
                        active={'active'}
                        duration={3000}
                        durationCallback={() =>
                            actions.setState({
                                key: 'pageError',
                                value: {
                                    ...state.pageError,
                                    displayed: false,
                                },
                            })
                        }
                        message={state.pageError.message}
                    ></MessagePopup>
                )}
                <p class='sup-title' style={{ 'margin-bottom': '35px' }}>
                    {t('Modifications d\'équipe médicale')}
                </p>
                <Form
                    classes={'col-xs-12 sup-custom-form btzForm'}
                    onsubmit={(event) => event.preventDefault()}
                    autocomplete='off'
                >
                    <div
                        className={
                            'sup-group-form ' +
                            (state.errors.form.listCaregiverId.length > 0
                                ? 'field-is-in-error'
                                : '')
                        }
                    >
                        <label class={'sup-label'}>
                            {'Soignant(s) à ajouter / retirer'}
                        </label>
                        <div className={'sup-group-form-input-wrapper'}>
                            <textarea
                                class='input-textarea'
                                type={'text'}
                                name={'doctors-toedit'}
                                rows={'3'}
                                input={state.datas.doctors.listCaregiverId}
                                placeholder={
                                    'Saisissez les ID des soignants à ajouter / retirer en les séparant par une ,'
                                }
                                onkeydown={(e) => {
                                    const isRestricted = restrictToRegex(
                                        e.key,
                                        /^[a-z0-9,]+$/i,
                                    )
                                    if (!isRestricted) e.preventDefault()
                                }}
                                oninput={(e) => {
                                    actions.resetErrors('listCaregiverId')
                                    actions.setState({
                                        key: 'datas',
                                        value: {
                                            ...state.datas,
                                            doctors: {
                                                ...state.datas.doctors,
                                                listCaregiverId: e.target.value,
                                            },
                                        },
                                    })
                                }}
                            />
                            <p class='field-is-in-error-message'>
                                {state.errors.form.listCaregiverId.map(
                                    (err) => (
                                        <span>
                                            {err}
                                            <br />
                                        </span>
                                    ),
                                )}
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            'sup-group-form ' +
                            (state.errors.form.caregiverTeamSupervisorId
                                .length > 0
                                ? 'field-is-in-error'
                                : '')
                        }
                    >
                        <label class={'sup-label'}>
                            {'Dans l’équipe médicale du soignant'}
                        </label>
                        <div className={'sup-group-form-input-wrapper'}>
                            <input
                                class='input-textarea'
                                type={'text'}
                                name={'doctor-caregiverTeamSupervisorId'}
                                placeholder={
                                    'Saisissez l’ID du soignant dans lequel ajouter / retirer les soignants de l’équipe médicale'
                                }
                                input={
                                    state.datas.doctors
                                        .caregiverTeamSupervisorId
                                }
                                onkeydown={(e) => {
                                    const isRestricted = restrictToRegex(
                                        e.key,
                                        /^[a-z0-9]+$/i,
                                    )
                                    if (!isRestricted) e.preventDefault()
                                }}
                                oninput={(e) => {
                                    actions.resetErrors(
                                        'caregiverTeamSupervisorId',
                                    )
                                    actions.setState({
                                        key: 'datas',
                                        value: {
                                            ...state.datas,
                                            doctors: {
                                                ...state.datas.doctors,
                                                caregiverTeamSupervisorId:
                                                e.target.value,
                                            },
                                        },
                                    })
                                }}
                            />
                            <p class='field-is-in-error-message'>
                                {state.errors.form.caregiverTeamSupervisorId.map(
                                    (err) => (
                                        <span>
                                            {err}
                                            <br />
                                        </span>
                                    ),
                                )}
                            </p>
                        </div>
                    </div>
                    <Form.Group classes='btzForm-btzFooter btzForm-btzFooter-btzSubmits'>
                        <Button
                            primary
                            flat
                            active
                            dangerous
                            loading={state.formIsSubmitting}
                            onclick={() => {
                                actions.updateDoctorsTeam('remove')
                            }}
                        >
                            {t('Retirer les docteurs')}
                        </Button>
                        <Button
                            primary
                            flat
                            active
                            loading={state.formIsSubmitting}
                            onclick={() => {
                                actions.updateDoctorsTeam('add')
                            }}
                            style={{ margin: 'auto auto auto 25px' }}
                        >
                            {t('Ajouter les docteurs')}
                        </Button>
                    </Form.Group>
                </Form>

                { applicationName=='oncoflow' && (
                    <div>
                <hr />
                <p className='sup-title' style={{ 'margin-bottom': '35px' }}>
                    {t('Modifications de la liste des sources d’interopérabilité via appel contextuel')}
                </p>
                <div className='tableWrapper'>
                    <table>
                        <thead>
                        <tr className='customtable-head'>
                            <th></th>
                            <th>{t('Sources d\'interopabilité')}</th>
                            <th>{t('Noms des organisations associées')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.datas.interopSources.entry?.map((interopSource) => {
                            return (
                                <tr className='customtable-row'>
                                    <td>
                                        <input id={interopSource.label} type='radio' onclick={() => actions.setState({
                                            key: 'datas',
                                            value: {
                                                ...state.datas,
                                                interopSourceInputValue: interopSource.label,
                                            },
                                        })}
                                        checked={state.datas.interopSourceInputValue==interopSource.label}/>
                                    </td>
                                    <td className='green-colored' >
                                        <label for={interopSource.id + 'input'}>{interopSource.label}</label>
                                    </td>
                                    <td className="green-colored">
                                        <div>
                                            {
                                                interopSource.customers.map((customer, index) => customer.name).join(', ')
                                                }
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <Form onsubmit={(event) => event.preventDefault()}>
                    <div className='sup-group-form'>
                        <label className='sup-label'>
                            {'Source d’interopérabilité via appel contextuel à ajouter / retirer'}
                        </label>
                        <div className='sup-group-form-input-wrapper'>
                            <input
                                className='input-textarea'
                                type={'text'}
                                name={'interopsource-toedit'}
                                value={state.datas.interopSourceInputValue}
                                oninput={(e) => {
                                    actions.setState({
                                        key: 'datas',
                                        value: {
                                            ...state.datas,
                                            interopSourceInputValue: e.target.value,
                                        },
                                    })
                                }}
                                placeholder={
                                    'Saisissez  l\'id de la source à ajouter/ retirer \u000A Format attendu: pas de caractère spécial'
                                }
                            />
                        </div>
                    </div>

                    <Form.Group classes='btzForm-btzFooter btzForm-btzFooter-btzSubmits'>
                        <Button
                            primary
                            flat
                            active
                            dangerous
                            disabled={!state.datas.interopSourceInputValue
                                || state.datas.interopSources.entry?.every(source =>source.label !== state.datas.interopSourceInputValue)}
                            onclick={() => {
                                actions.modifyInteropSources('remove')
                            }}
                        >
                            {t('Supprimer la source d\'interop')}
                        </Button>
                        <Button
                            primary
                            flat
                            active
                            disabled={!state.datas.interopSourceInputValue
                                || state.datas.interopSources.entry.some(source =>source.label === state.datas.interopSourceInputValue)}
                            style={{ margin: 'auto auto auto 25px' }}
                            onclick={() => {
                                actions.modifyInteropSources('add')
                            }}
                        >
                            {t('Ajouter la source d\'interop')}
                        </Button>
                    </Form.Group>
                </Form>
                    </div>
                )}
                <hr />

                <p className='sup-title' style={{ 'margin-bottom': '35px' }}>
                    {t('Modifications de la liste des IP trustées')}
                </p>
                <Form onsubmit={(event) => event.preventDefault()}>
                    <div className='sup-group-form'>
                        <label className='sup-label'>
                            {'IP à ajouter / retirer (IP:label)'}
                        </label>
                        <div className='sup-group-form-input-wrapper'>
                            <textarea
                                className='input-textarea'
                                type={'text'}
                                name={'ip-toedit'}
                                input={state.datas.trustIPs}
                                oninput={(e) => {
                                    actions.setState({
                                        key: 'datas',
                                        value: {
                                            ...state.datas,
                                            trustIPs: e.target.value,
                                        },
                                    })
                                }}
                                placeholder={
                                    'Saisissez les IP à ajouter / retirer avec leur label, séparés par une virgule , \u000A Format attendu : 0.0.0.0:nomAssocie, 1.1.1.1:deuxiemeNomAssocie'
                                }
                            />
                        </div>
                    </div>

                    <Form.Group classes='btzForm-btzFooter btzForm-btzFooter-btzSubmits'>
                        <Button
                            primary
                            flat
                            active
                            dangerous
                            onclick={() => {
                                actions.modifyTrustIP('remove')
                            }}
                        >
                            {t('Supprimer l\'IP')}
                        </Button>
                        <Button
                            primary
                            flat
                            active
                            style={{ margin: 'auto auto auto 25px' }}
                            onclick={() => {
                                actions.modifyTrustIP('add')
                            }}
                        >
                            {t('Ajouter l\'IP')}
                        </Button>
                    </Form.Group>
                </Form>
                <div className='tableWrapper'>
                    <table>
                        <thead>
                        <tr className='customtable-head'>
                            <th>{t('Adresse IP')}</th>
                            <th>{t('Label')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.datas.initialTrustIPs.map((trustIP) => (
                            <tr className='customtable-row'>
                                <td className='green-colored'>
                                    {trustIP.ip}
                                </td>
                                <td className='green-colored'>
                                    {trustIP.label}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )

export default Component(state, actions, view, 'adminoperations-healthteam')
